import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql, Link } from "gatsby";

function AlumniStories({ type }) {
  const stories = useStaticQuery(graphql`
    query AlumniStories {
      all: allMdx(
        sort: { fields: [frontmatter___date], order: DESC }
        filter: { frontmatter: { tags: { eq: "alumni-stories" } } }
      ) {
        nodes {
          frontmatter {
            title
            headline
            subheadline
            thumbnail
            metadata {
              Description
            }
          }
          body
          slug
        }
      }
      limit: allMdx(
        filter: { frontmatter: { tags: { eq: "alumni-stories" } } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 6
      ) {
        nodes {
          frontmatter {
            title
            headline
            subheadline
            thumbnail
            metadata {
              Description
            }
          }
          body
          slug
        }
      }
    }
  `);

  function AllStories() {
    return (
      <Row className="alumni-stories">
        {stories.all.nodes.map((node, index) => (
          <Col xl={6} key={index++} className="px-3 mb-3 mb-lg-4 mb-xl-5">
            <Row className="align-items-center">
              <Col xl={12} lg={6} xs={12}>
                <Link to={`/` + node.slug}>
                  <img
                    src={node.frontmatter.thumbnail}
                    alt={`${node.frontmatter.title} thumbnail`}
                    className="img-fluid mb-4"
                    loading="lazy"
                  />
                </Link>
              </Col>
              <Col xl={12} lg={6} xs={12}>
                <Link to={`/` + node.slug}>
                  <h2 className="stories-title">{node.frontmatter.headline}</h2>
                </Link>
                <div className="stories-description">
                  <p>
                    <Link to={`/` + node.slug}>
                      {node.frontmatter.metadata.Description}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }

  function LimitStories() {
    return (
      <Row className="alumni-stories">
        {stories.limit.nodes.map((node, index) => (
          <Col xl={6} key={index++} className="px-3 mb-4 mb-xl-5">
            <Row className="align-items-center">
              <Col xl={12} lg={6} xs={12}>
                <Link to={`/` + node.slug}>
                  <img
                    src={node.frontmatter.thumbnail}
                    alt={`${node.frontmatter.headline} thumbnail`}
                    className="img-fluid mb-4"
                    loading="lazy"
                  />
                </Link>
              </Col>
              <Col xl={12} lg={6} xs={12}>
                <Link to={`/` + node.slug}>
                  <h2 className="stories-title">{node.frontmatter.title}</h2>
                </Link>
                <div className="stories-description">
                  <p>
                    <Link to={`/` + node.slug}>
                      {node.frontmatter.metadata.Description}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }

  if (type === "all") {
    return <AllStories />;
  }
  if (type === "limit") {
    return <LimitStories />;
  } else {
    return null;
  }
}

export default AlumniStories;
