import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SSRProvider from "react-bootstrap/SSRProvider";

import AlumniQuotes from "../components/alumni/alumniQuotes";
import AlumniStories from "../components/alumni/alumniStories";
import { AlumniVideos } from "../components/alumni/alumniVideo";
import ContentQuoteCenter from "../components/contentQuoteCenter.js";
import DefaultHeader from "../components/default-header";
import Footer from "../components/footer";
import GdprPanel from "../components/gdpr";
import Seo from "../components/seo";
import Navigation from "../components/navigation";
import NewsletterSignup from "../components/newsletterSignUp.js";

import "../styles/alumni.scss";

export default function DegreePage({ data }) {
  const post = data.mdx;

  const shortcodes = {
    Container,
    Row,
    Col,
    Link,
    AlumniQuotes,
    AlumniVideos,
    AlumniStories,
    ContentQuoteCenter,
  };

  return (
    <>
      <SSRProvider>
        <Navigation />
        <DefaultHeader
          headline={post.frontmatter.headline}
          heroBackground={post.frontmatter.heroBackground}
          heroMobileBackground={post.frontmatter.heroMobileBackground}
          subHeadline={post.frontmatter.subheadline}
        />
        <div id="body" className="alumni">
          <MDXProvider components={shortcodes}>
            <MDXRenderer>{post.body}</MDXRenderer>
          </MDXProvider>
        </div>
        <Footer />
        <GdprPanel />
        <NewsletterSignup />
      </SSRProvider>
    </>
  );
}

export const Head = ({ data }) => (
  <Seo
    title={data.mdx.frontmatter.metadata.Title}
    description={data.mdx.frontmatter.metadata.Description}
    pathname={data.mdx.frontmatter.metadata.Link}
    ogImage={data.mdx.frontmatter.metadata.ogImage}
    cardImage={data.mdx.frontmatter.metadata.cardImage}
  />
);

export const query = graphql`
  query ($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        heroBackground
        heroMobileBackground
        subheadline
        headline
        title
        headerType
        metadata {
          Description
          Keywords
          Link
          Title
          ogImage
          cardImage
        }
        menuType
        subNav {
          text
          url
        }
      }
      body
    }
  }
`;
