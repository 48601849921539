import * as React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql, Link } from "gatsby";

export function AlumniVideos({ page }) {
  const data = useStaticQuery(graphql`
    query AlumniVideos {
      allMdx(
        filter: { frontmatter: { tags: { eq: "student" } } }
        sort: { fields: frontmatter___publishedDate, order: DESC }
        limit: 2
      ) {
        nodes {
          id
          slug
          frontmatter {
            headline
            videoThumb
            videoLink
            title
            subheadline
            target
            runTime
            publishedDate
          }
          body
        }
      }
    }
  `);

  return (
    <Container fluid="lg">
      <Row className="alumni-profiles justify-content-around py-xxl-5 mt-xxl-5 py-md-4 mt-md-4 mx-3 mx-lg-auto">
        <Col className="col-12 opaque-gray-bg p-xl-5 p-4 rounded">
          <Row className="align-items-center">
            <Col md={6} xs={12}>
              <h2 className="mb-md-0">The Latest Video Interviews</h2>
            </Col>
            <Col md={6} xs className="text-md-end">
              <p>
                <Link
                  to="/about/video-center/student-profiles"
                  className="btn styled-link"
                >
                  See More In Video Center{" "}
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.20918 0L8.20923 5L3.20918 10L1.7908 8.58152L5.37237 5L1.7908 1.41848L3.20918 0Z"
                      fill="#0078AE"
                    />
                  </svg>
                </Link>
              </p>
            </Col>
            <Col xs={12} className="py-4 mb-3">
              <span className="green-border-bottom"></span>
            </Col>
          </Row>
          <Row>
            {data.allMdx.nodes.map((node, index) => (
              <Col xs={12} lg={6} className="pt-0 mb-4 mb-lg-0" key={index}>
                <Link to={`/${node.slug}`}>
                  <Row>
                    <Col xs={12} md={6} lg={12}>
                      <div className="video-thumb mb-4 mx-auto text-center">
                        <img
                          src={node.frontmatter.videoThumb}
                          className="img-fluid ms-auto"
                          alt={`${node.frontmatter.title} thumbnail`}
                        />
                        <div className="run-time">
                          {node.frontmatter.runTime}
                        </div>
                        <div className="play-icon">
                          <span>
                            <svg
                              width="35"
                              height="35"
                              viewBox="0 0 12 15"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.947704 0.684082V14.4096L11.732 7.54683L0.947704 0.684082Z"
                                fill="white"
                              />
                            </svg>
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} md={6} lg={12}>
                      <h4 className="mb-2">{node.frontmatter.title}</h4>
                      <MDXRenderer>{node.body}</MDXRenderer>
                    </Col>
                  </Row>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
}
